.card__container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  gap: 40px 40px;
  //margin-top: 50px;
  margin-bottom: 20px;
}

@media screen and (max-width: 780px) {
  .card__container {
    gap: 20px;
    margin-top: 5px;
  }
}
///////////////////////////////////  //position: relative;
.card__wrapper {
  position: relative;
  width: 100%;
  max-width: 320px;
}

.card__wrapper__bezVidigrashu {
  margin-top: 20px;
}

.card__offer {
  background: #FCFAE9;
  //box-shadow: -4px 4px 12px rgb(0, 0, 0);
  //box-shadow: -4px 4px 12px rgb(165 165 165);
  border-radius: 15px;
  margin: 0 auto;
  overflow: hidden;
}

.marquee__container {
  //background: linear-gradient(90deg, #F01A83 0%, #FC6767 100%);
  border-radius: 10px 10px 0px 0px;
  height: 41px;
  //position: absolute;
  top: -20px;
  width: 100%;
  padding-top: 2px;
}
.marquee__text {
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin: 0 10px;
}
//////////////////////////////////////////////////////////////////////////////////////////

.b-marquee {
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  //padding: 10px;
  //position: relative;
  margin-bottom: 10px;
  background:  linear-gradient(90deg, #F01A83 0%, #FC6767 100%);


  border-radius: 10px 10px 0px 0px;
  height: 41px;
  position: absolute;
  top: -20px;
  width: 100%;
  padding-top: 2px;
}

.b-marquee__text {
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin: 0 10px;
  //-webkit-animation: animMarquee 30s linear infinite;
  //animation: animMarquee 30s linear infinite;
}

.b-marquee--rtl .b-marquee__text {
  -webkit-animation: animMarqueeRtl 30s linear infinite;
  animation: animMarqueeRtl 30s linear infinite;
}

@-webkit-keyframes animMarqueeRtl {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(-1000%);
    transform: translateX(-1000%);
  }
}

@keyframes animMarqueeRtl {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(-1000%);
    transform: translateX(-1000%);
  }
}

//////////////////////////////////////////////////////////////////////////////////////////

.card__top {
  position: relative;
  width: 100%;
  height: 83px;
  min-height: 83px;
  padding: 12px;
  display: flex;
}

.offer_tag {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 5px 13px;
  height: 27px;
  border-radius: 6px;
  //backdrop-filter: blur(10px);
}


.card__img__title__container {
  display: flex;
  margin-left: 20px;
  height: 30px;
}

.card__img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  position: relative;
  top: -30px;
}

////////////////////  good
.card__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  //font-size: 25px;
  font-size: 23px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #000057;
  margin-left: 3px;
}

.card__check__icon {
  width: 21px;
  height: 20px;
  object-fit: contain;
  margin: 5px;
}

.card__text__container {
  padding: 8px 12px 8px 25px;
}

.card__text__bonus {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  //font-size: 28px;
  line-height: 29px;
  //line-height: 34px;
  color: #000057;
}

.card__text__bezvidigrashu {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  //font-size: 28px;
  font-size: 23px;
  //line-height: 34px;
  line-height: 29px;
  text-transform: uppercase;
  color: #E02C21;
}

.card__text__terms {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  //font-size: 17px;
  font-size: 15px;
  line-height: 21px;
  color: #000057;
  opacity: 0.6;
}

.card__offer__promo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 12px 25px;
}

.card__offer__text__container {
  background: rgba(242, 227, 90, 0.46);
  border: 2px dashed rgba(0, 0, 87, 0.4);
  border-radius: 10px;
  width: 130px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__offer__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  //text-transform: uppercase;
  color: #000057;
}

.card__offer__text__stars {
  padding-top: 8px;
}


.card__offer__btn {
  background: linear-gradient(180deg, #81DC61 0%, #6DD549 100%);
  //box-shadow: 0px 4px 4px rgba(118, 216, 83, 0.4);
  border-radius: 11px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 130px;
  height: 46px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__offer__nopromo {
  background: rgba(251, 239, 133, 0.46);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 330px;
  width: 290px;
  height: 58px;
  margin: 9px auto;
}

.card__offer__nopromo__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  text-transform: uppercase;

  color: #000057;
}

.card__btn__container {
  display: flex;
  justify-content: center;
}


.card__btn {
  width: 290px;
  height: 58px;
  background: linear-gradient(0deg, #60A1EC, #60A1EC);
  //box-shadow: 0px 4px 8px rgb(103, 146, 255);
  border-radius: 12px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
}

.card__bottom {
  display: flex;
  justify-content: space-around;
  padding-bottom: 12px;
  padding-top: 15px;
  max-width: 290px;
  margin: 0 auto;
  width: 100%;
}

.card__like__container, .card__share__container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.card__like__img {
  cursor: pointer;
  background-size: contain;
  width: 22px;
  height: 20px;
}

.card__bottom__border {
  height: 25px;
  width: 1px;
  background: rgba(0, 0, 87, 0.3);
}

.card__like__text, .card__share__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000057;
  margin-left: 5px;
}

.card__like__text {
  cursor: pointer;
}

@media screen and (min-width: 660px) {
  .card__middle {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .card__offer {
    min-height: 360px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  //.card__wrapper__bezVidigrashu {
  //  margin: 0;
  //}

  .card__text__container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 420px) {
  .card__wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 390px) {
  .card__offer__nopromo {
    width: 90%;
  }
  .card__btn {
    width: 90%;
  }
  .card__text__bonus, .card__text__bezvidigrashu {
    font-size: 24px;
    line-height: 30px;
  }
  .card__offer__text__container {
    width: 49%;
  }
  .card__offer__btn__container {
    width: 49%;
  }
  .card__offer__btn {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .card__bottom {
    width: 90%;
  }
}

@media screen and (max-width: 355px) {
  .card__text__bonus, .card__text__bezvidigrashu {
    font-size: 23px;
    line-height: 28px;
  }
  .card__text__terms {
    font-size: 16px;
    line-height: 20px;
  }
  .card__like__text, .card__share__text {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 330px) {
  .card__text__bonus, .card__text__bezvidigrashu {
    font-size: 22px;
    line-height: 28px;
  }
  .card__text__terms {
    font-size: 15px;
    line-height: 19px;
  }
  .card__offer__text {
    font-size: 15px;
    line-height: 19px;
  }
  .card__offer__btn {
    font-size: 15px;
    line-height: 19px;
  }
}
