.register__fb {
  background: url("../../assets/RegisterFB/bg.png") center center repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  font-family: 'Heading Pro', sans-serif;
  color: #FFFFFF;
}

@media screen and (min-height: 900px) {
  .register__fb {
    height: 100%;
  }
}

@media screen and (max-width: 460px) {
  .register__fb {
    background-size: cover;
  }
}

.register__fb__container {
  max-width: 430px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  //overflow: hidden;
}

@media screen and (max-width: 630px) {
  .register__fb__container {
    overflow: hidden;
  }
}


.fb__logo__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.fb__logo__title {
  display: flex;
}

.fb__logo {
  height: 43px;
  width: 42px;
  object-fit: contain;
}

.fb__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 88.4%;
  color: #FCFAE9;
}

.fb__title__yellow {
  color: #F4D112;
  font-weight: 800;
}

.register__fb__login__container{
  background: #F4D112;
  border-radius: 40px;
  width: 132px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__fb__login {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 88.4%;
  color: #000000;
  text-decoration: none;
}

.fb__get__container {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fb__img__bonus {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 369px;
  max-height: 252px;
}

.fb__img__bezVidigrashu_container {
  margin-top: 25px;
}

.fb__img__bezVidigrashu {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 392px;
  max-height: 121px;
  position: relative;
  right: 130px;
}

.register__get__btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  position: relative;
}

.register__get__btn__container::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -75px;
  background: url("../../assets/RegisterFB/cube1.png") center center no-repeat;
  background-size: contain;
  width: 163px;
  height: 159px;

}

.register__get__btn__container::after {
  content: "";
  position: absolute;
  bottom: 40px;
  right: -100px;
  background: url("../../assets/RegisterFB/cube2.png") center center no-repeat;
  background-size: contain;
  width: 263px;
  height: 250px;
}

.register__get__btn {
  margin: 0 auto;
  width: 310px;
  height: 85px;
  background: #F4D112;
  box-shadow: 0px 12px 21px rgba(244, 113, 18, 0.4), 0px 5px 0px #CC730B;
  border-radius: 79px;
  border: none;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 30px;
  line-height: 88.4%;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}

.fb__img__cube__container {
  height: 200px;
  overflow: hidden;
}

.fb__img__cube {
  width: 100%;
  height: auto;
  position: relative;
  top: 60px;
}

//////////////////////////////////////////
/////////////POPUP
/////////////////////////////////////////
.popup__register__fb__container.active {
  display: flex;
  z-index: 2;
  overflow: auto;
}

body.popup__register__fb__container.active {
  overflow: hidden;
  position: fixed;
}

.popup__register__fb__container {
  width: 100vw;
  height: 100vh;
  background: rgba(9, 9, 9, 0.4);
  backdrop-filter: blur(14px);
  position: fixed;
  top: 0;
  display: none;
  justify-content: center;
  transition: all 1s ease-in-out;
}

.popup__register__fb.active {
  transform: translate(0, 2%);
  transition: all 1s ease-in-out;
}

.registration-login.fb {
  background: #171717;
}


.popup__register__fb {
  //background: #171717;
  border-radius: 16px;
  width: 100%;
  max-width: 390px;
  margin-bottom: 50px;
  //margin: 0 auto;
  height: auto;
  //padding: 15px 25px;
  transform: translate(0, -115%);
  transition: all 1s ease-in-out;
  position: relative;
}

.cross__popup {
  position: absolute;
  top: 25px;
  right: 13px;
  cursor: pointer;
}

.registration-login__title.fb {
  color: white;
}

.label__inp.fb {
  color: white;
}
.input__container.fb{
  margin-top: 15px;
}

.input__name__icon.fb {
  position: absolute;
  bottom: 9px;
  left: 5px;
  background: url("../../assets/RegisterFB/user.png") center center no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}

.input__email__icon.fb {
  position: absolute;
  background: url("../../assets/RegisterFB/email.png") center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 15px;
  bottom: 9px;
  left: 5px;
}

.input__password__icon.fb {
  position: absolute;
  bottom: 9px;
  left: 5px;
  background: url("../../assets/RegisterFB/lock.png") center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.input__repeatPassword__icon.fb {
  position: absolute;
  bottom: 9px;
  left: 5px;
  background: url("../../assets/RegisterFB/lock.png") center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.input.fb {
  color: rgba(252, 250, 233, 0.5) !important;
}

.registration-login__btn__continue.fb {
  background: #F4D112;
  box-shadow: 0px 4px 15px rgba(244, 209, 18, 0.3);
  border-radius: 5px;
  color: #171717;
}

.registration-login__btn__container__text.fb {
  color: rgba(252, 250, 233, 0.5);
}
.fb input[type=checkbox]:checked:before{
  background: #F4D112;
}
.fb input[type=checkbox]:checked:after{
  background: url("../../assets/Login.Registr/checkBlack.png") center center no-repeat;
  background-size: contain;
}
.registration-login__checkbox__text.fb{
  color: #FCFAE9;
}

@media screen and (max-height: 750px) {
  .fb__get__container{
   margin-top: 15px;
  }
  .fb__img__bonus{
    max-height: 210px;
  }
  .fb__img__bezVidigrashu{
    max-height: 90px;
  }
  .register__get__btn__container{
    margin-top: 50px;
  }
  .register__get__btn__container::after{
    bottom: 30px;
    right: -85px;
    width: 200px;
    height: 200px;
  }
  .register__get__btn__container::before{
    bottom: 10px;
    left: -50px;
    width: 130px;
    height: 130px;
  }
}


