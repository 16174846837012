.footer {
  font-family: 'Montserrat', sans-serif;
  background: #CCCFFF;
  border-radius: 25px 25px 0px 0px;
  width: 92%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 17px 25px;
  z-index: 1;
}

.footer__sub__title__number {
  font-weight: 700;
  font-size: 36px;
  line-height: 31px;
  text-align: center;
  color: #4C4E73;
}

.footer__text__copyright {
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: #4C4E73;
  margin-top: 4px;
}
.footer__sub__container{
  //width: 50%;
  margin: 0 auto;
  max-width: 310px;
}
.footer__sub__title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: #4C4E73;
  //margin-top: 25px;
}

.footer__sub__title:nth-child(odd) {
  //margin-top: 40px;
}

.footer__text {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
  color: #4C4E73;
  margin-top: 6px;
}

@media screen and (min-width: 1100px) {
  .footer {
    display: flex;
    justify-content: space-between;
    //align-items: center;
  }
  .footer__sub__container {
    max-width: 310px;
  }
  .footer__sub__title__number {
    margin-bottom: 3px;
  }
  .footer__text__copyright {
    width: 50%;
    margin: 0px auto;

  }
}

@media screen and (max-width: 600px) {
  .footer__sub__container{
    width: 100%;
  }
}