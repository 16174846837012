.thankPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/RegisterFB/bg.png") center center repeat;
  background-size: contain;
  color: #FFFFFF;
  height: 100vh;
  width: 100vw;
  font-family: Montserrat, sans-serif;

}
@media screen and (max-width: 460px) {
  .thankPage {
    background-size: cover;
  }
}
.thankPage__container {
  width: 360px;
  height: auto;
  background: #2B2B2B;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
}

.thankPageWithout {
  height: 300px;
  align-items: start;
}

.thankPage__title {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;

}

.thankPageWithout__title {
  margin: 0 auto;
}

.thankPage__img {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.thankPage__text {
  margin-top: 25px;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.thankPageWithout__text {
  margin-top: 15px;
}

.thankPage__list__container {
  margin-top: 20px;
}

.thankPage__list {
  list-style: inside;
}

.thankPage__list__span {
  color: #B52929;
  font-weight: 600;
}

@media screen and (min-width: 900px) {
  .thankPage__container {
    width: 410px;
  }

  .thankPage__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .thankPage__text {
    font-size: 18px;
    line-height: 22px;
  }
  .btn{
    width: 310px;
  }
}
@media screen and (max-width: 370px) {
  .thankPage__container {
    width: 90%;
  }
}

@media screen and (max-width: 320px) {
  .thankPage__container {
    height: auto;
  }
}
