.promo {
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.promo__img__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo__img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.promo__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin-top: 2px;
}

.offer__none__container {
  padding: 15px 20px 20px 20px;
  width: 350px;
  margin: 30px auto;
  background: #FCFAE9;
  box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.offer__none__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #000057;
  margin-bottom: 22px;

}

.offer__none__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000057;
}

.offer__none__link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  display: block;
  text-decoration: underline;
  color: #000057;
  margin-top: 22px;
}

@media screen  and (max-width: 370px){
  .offer__none__container{
    width: 96%;
  }
  .offer__none__title{
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 12px;
  }
  .offer__none__text{
    font-size: 14px;
    line-height: 20px;
  }
}