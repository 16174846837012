.register__push__container {
  width: 100vw;
  height: 100vh;
  background: rgba(113, 126, 247, 0.4);
  -webkit-backdrop-filter: blur(21px);
  position: fixed;
  top: 0;
  display: none;
  transition: all .5s ease-in-out;

  &.active {
    display: block;
    z-index: 22;
  }
}

.register__push {
  background: #FCFAE9;
  box-shadow: 0px 0px 40px #502388;
  border-radius: 12px;
  width: 320px;
  margin: 0 auto;
  height: 240px;
  padding: 100px 15px 15px;
  transform: translate(0, -115%);
  transition: all .5s ease-in-out;
  position: relative;
  &.active {
    transform: translate(0, 75%);
  }
}

.register__push__img {
  width: 152px;
  height: 133px;
  position: absolute;
  top: -50px;
  left: 95px;
}

.register__push__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 130%;
  text-align: center;
  color: #000057;
}

.register__push__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  text-align: center;
  color: #000057;
  margin-top: 4px;
}

.register__push__btn {
  margin: 16px auto;
  background: #7BDA59;
  box-shadow: 0px 4px 8px rgba(123, 218, 89, 0.4);
  border-radius: 6px;
  width: 154px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 88.4%;
  color: #FFFFFF;
  text-decoration: none;
}

@media screen and (max-width: 365px) {
  .register__push {
    width: 310px;
  }
  .register__push__img {
    left: 80px;
  }
  .register__push__title {
    font-size: 17px;
  }
  .register__push__text {
    font-size: 15px;
  }
}