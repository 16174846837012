@import "./reset";
@import "./layout";
@import "./main";
@import "./cardOffer";
@import "./header";
@import "./footer";
@import "./Promo";
@import "./burger";
@import "./registration";
@import "./Profile";
@import "./Promokod";
@import "./Popup";
@import "./resetPassword";
@import "./pwa";
@import "./registerPush";
@import "./chatManager";
@import "RegistrationFacebook/RegisterFB";
@import "RegistrationFacebook/thankPage";


//.sp-safari.sp-prompt {
//  top: 0 !important;
//}
//.add-to-home-screen-pwa__notify p.heading{
//  font-weight: 700;
//}
//.add-to-home-screen-pwa__notify p {
//  margin: 10px 0;
//}
//
//.add-to-home-screen-pwa__notify button {
//  background: linear-gradient(0deg, #60a1ec, #60a1ec), linear-gradient(266.89deg, #ff6363 11.18%, #ffb74a 91.19%), linear-gradient(180deg, #5eec9f, #21bf73), linear-gradient(180deg, #7cec8e, #4edb65), #fff;
//}

* {
  -webkit-tap-highlight-color: transparent!important;
  &:focus-visible ,&:visited, &:hover,&:active,&:focus,&:target{
    outline: none!important;
  }
}
